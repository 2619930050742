import React from "react";
import "./about.scss";
import logo from "../../Media/Summarecon Bogor.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6287874691787&text=Halo%20Heldy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bogor%20(About)%20https://marketingrumahbogor.com//&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const about = () => {
  return (
    <div id="about" className="container-about">
      <div className="container-deskripsi">
        <div className="judulsumbog">
          <h1>The Warmest Experience to Our Residents.</h1>
        </div>
        <div className="deskripsi-about">
          Summarecon Bogor adalah sebuah kawasan mandiri yang dikembangkan oleh
          PT Summarecon Agung Tbk, terletak di Sukaraja, Bogor, Jawa Barat.
          Dengan luas sekitar 500 hektar, proyek ini menawarkan konsep green
          living dan dirancang untuk mengintegrasikan kehidupan kota dengan
          kenyamanan alam. Kawasan ini dilengkapi dengan berbagai fasilitas
          modern, seperti:
        </div>
      </div>
      <div className="rowcontainer">
        <div className="featurelist">
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp; Strategic Location
            </span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Engage with Daily Life Activity
            </span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Connecting With Nature
            </span>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>&nbsp;&nbsp;Elegant Lifestyle</span>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>&nbsp;&nbsp;Endless Facilities</span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Perfect Modern Luxury & luxurious Residence
            </span>
          </div>
          <div className="button">
            <div className="container-wa">
              <button
                className="buttonpenawaran"
                onClick={handleConsultationClick}>
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className="logo-about">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-summarecon-serpong"
          />
        </div>
      </div>
    </div>
  );
};

export default about;
