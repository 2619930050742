import React from "react";
import "./TheEbony.scss";
import data from "./data.json";
import Card from "../../Component/Card Ebony/card";
import Ebony7images from "../../Media/Summarecon Bogor/Ebony/Ebony7";
import Ebony7Atticimages from "../../Media/Summarecon Bogor/Ebony/Ebony7Attic";
import Ebony9images from "../../Media/Summarecon Bogor/Ebony/Ebony9";
import Ebony9Atticimages from "../../Media/Summarecon Bogor/Ebony/Ebony9Attic";

const imageMap = {
  Ebony7images,
  Ebony7Atticimages,
  Ebony9images,
  Ebony9Atticimages,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Ebony" className="produk">
      <h1>Welcome to The Ebony Residence</h1>
      <h2>Experience a lifestyle with a fresh and calming ambience</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
