import React from "react";
import "./home.scss";
import Header from "../Section/Header/navbar";
import Banner from "../Section/Banner/Banner";
import About from "../Section/about/about";
import Soon from "../Section/comingsoon/soon";
import Ebony from "../Section/TheEbony/TheEbony.jsx";
import Produk from "../Section/Produk/produk";
import Fasilitas from "../Section/Fasilitas/fasilitas.jsx";
import Surrounding from "../Section/surrounding/Surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import ScrollToHashElement from "./ScrollToHashElement";
import Tombol from "../Media/tombol.webp";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287874691787&text=Halo%20Heldy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bogor%20https://marketingrumahbogor.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <Banner />
      <About />
      <Soon />
      <Ebony />
      <Produk />
      <Fasilitas />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={Tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
