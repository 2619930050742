import React from "react";
import "./Surrounding.scss";
import aeon from "../../Media/Surrounding/Ekoplaza.webp";
import prasmul from "../../Media/Surrounding/IPB.webp";
import binus from "../../Media/Surrounding/Penabur.webp";
import breeze from "../../Media/Surrounding/Botani.webp";
import qbig from "../../Media/Surrounding/Teras.webp";
import eka from "../../Media/Surrounding/Nako.webp";
import tol from "../../Media/Surrounding/Tol Bogor Selatan.webp";
import sekolah from "../../Media/Surrounding/LRT.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Surrounding = () => {
  const slides = [
    {src: aeon, des: "Ekalokasari Lippo Plaza"},
    {src: prasmul, des: "IPB University"},
    {src: binus, des: "Penabur School"},
    {src: breeze, des: "Botani Square"},
    {src: qbig, des: "Teras by Plataran"},
    {src: eka, des: "Kopi Nako"},
    {src: tol, des: "Tol Bogor Selatan"},
    {src: sekolah, des: "LRT Access"},
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="surrounding" className="surroundingcontainer">
      <div className="title">Surrounding Area</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Surrounding;
