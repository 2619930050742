import React from "react";
import "./soon.scss";
import Comingsoon from "../../Media/Soon_Desktop.webp";
import Soon from "../../Media/Soon_Mobile.webp";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6287874691787&text=Halo%20Heldy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bogor%20(The%20Maple%20Hill)%20https://marketingrumahbogor.com//&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const soon = () => {
  return (
    <div id="Maple" className="container-soon">
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={Comingsoon} alt="maps" />
        <img id="lokasi" className="img-lokasi-mobile" src={Soon} alt="maps" />
      </div>
      <div className="button">
        <div className="container-wa">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default soon;
